/**
 * login模块接口列表
 */
import axios from '@/service/api/http' // 导入http中创建的axios实例
const api = {
    // // get请求不带参数
    // getAlldata() {
    //   return axios.get(`/lsx/getAll`)
    // },
    // get请求带参数
    // articleDetail(params) {
    //   return axios.get('/api/', {
    //     params: params
    //   })
    // },
    // post提交
    // login(params) {
    //   return axios.post('/api/shop', params)
    // }
    // 其他接口…………
    // 登录接口.............................................................................
    login(params) {
        return axios.post('/teacher/login',params)
    },

    // 首页接口......................................................................................
    home() {
        return axios.post('/teacher/getcoursecate')
    },
    // 首页课程列表接口
    homelist(params) {
        return axios.post('/teacher/getcoursebycate',params)
    },
    getlevelname() {
        return axios.get('/teacher/getlevelname',)
    },
    kcdetail(params) {
        return axios.post('/teacher/getclasscoursedetail',params)
    },
    // 作品管理。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。
    // 作品-班级分类
    classinit() {
        return axios.post('/teacher/getclass',)
    },
    // 作品列表
    worksinit(params) {
        return axios.post('/teacher/worklist',params)
    },
    // 作品详情
    workdetail(params) {
        return axios.post('/teacher/getworkdetail',params)
    },
	studentworklist(params) {
	    return axios.post('/teacher/studentworklist',params)
	},
    // 作页详情
    zydetail(params) {
        return axios.post('/teacher/getjobdetail',params)
    },
    // 作页上传班级学生选择
    classseletinit(params) {
        return axios.post('/teacher/getclasscourse',params)
    },
    // 作页上传
    zyup(params) {
        return axios.post('/teacher/sumbitwork',params)
    },
    // 相册管理。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。

    // 相册上传班级初始化接口
    getclasscourseall(params) {
        return axios.post('/teacher/getclasscourseall',params)
    },

    // 相册列表
    photpinit(params) {
        return axios.post('/teacher/albumlist',params)
    },
    // 相册详情
    photdetail(params) {
        return axios.post('/teacher/getalbumdetail',params)
    },
    // qn
    qn(params) {
        return axios.post('/teacher/getuploadsign',params)
    },
    // 上传相册
    upphoto(params) {
        return axios.post('/teacher/uploadpic',params)
    },
    
    delphoto(params) {
        return axios.post('/teacher/delpic', params)
    },
    // 班级管理。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。
    // 班级列表
    classList(id) {
        return axios.post('/teacher/myclass',id)
    },
    // 班级详情成员
    classDetail(params) {
        return axios.post('/teacher/classdetail',params)
    },
    // 删除班级详情成员
    delclassDetail(params) {
        return axios.post('/teacher/classmemberdel',params)
    },
    //班级详情导入学生
    uplead(params) {
        return axios.post('/teacher/importstudent',params)
    },
    // 审核班级详情成员
    checkclass(params) {
        return axios.post('/teacher/classmemberapplylist',params)
    },
    // 审核班级详情成员--通过
    passcheckclass(params) {
        return axios.post('/teacher/classmembercheckapply',params)
    },

    // 页面底部接口......................................................................................
    about() {
        return axios.post('/teacher/aboutus',)
    },
    relation() {
        return axios.post('/teacher/contactus',)
    },
    help() {
        return axios.post('/teacher/helpcenter',)
    },
    service() {
        return axios.post('/teacher/serviceonline',)
    },

    // footer_u() {
    //     return axios.post('/teacher/getclasscoursedetail')
    // }
    // home() {
    //     return axios.get('/api/v1.0/home/manager/')
    // },
    //
    // // 编辑上传商品
    // editGoods(params) {
    //     return axios.put('/api/v1.0/goods/release_or_edit/', params)
    // },
    //
    // // 商品删除接口
    // goodsDelete(id) {
    //     return axios.delete('/api/v1.0/goods/manager/', {params: id})
    // },

};

export default api
